import React, { useState } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 80px 20px;
  background-color: #fff;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #555;
  max-width: 1000px;
  margin: 20px auto;
  line-height: 1.6;
  text-align: left;
`;

const ToggleSection = styled.div`
  margin-bottom: 20px;
`;

const ToggleText = styled.span`
  font-size: 16px;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    color: #007bff;
  }
`;

const About = () => {
    // State to track visibility of paragraphs
    const [areParagraphsVisible, setAreParagraphsVisible] = useState(false);

    // Toggle function to show/hide paragraphs
    const toggleParagraphs = () => {
        setAreParagraphsVisible(!areParagraphsVisible);
    };

    return (
        <Section id="about">
            <Title>Introduction</Title>
            <h1>Engineering - Automotive Specialization</h1>
            <h1>Vehicle Testing  |  Automotive Technologies  |  Software Development</h1>
            <br />
            {/* Separate section with the +/– toggle */}
            <ToggleSection>
                <ToggleText onClick={toggleParagraphs}>
                    {areParagraphsVisible ? '−' : '+'} More
                </ToggleText>
            </ToggleSection>

            {/* Conditionally render paragraphs based on state */}
            {areParagraphsVisible && (
                <>
                    <Paragraph>
                        <li>Experienced Automotive professional, leading Vehicle Validation, Data Resim + Mining operations for ADAS Features.</li>
                        <li>Coordinate plans and execution for multiple projects, includes test architecture for feature coverage.</li>
                        <li>Results reported out for DVPnR step, SYS5 level on V model.</li>
                    </Paragraph>

                    <Paragraph>
                        <li>Active interest in autonomous mobility; MOOCs Certifications - Nanodegree on Self-Driving Car, Machine Learning</li>
                        <li>Projects on Lane Finding, Image Classification, Localization, Sensor Fusion using Kalman Filters, and Path Planning.</li>
                        <li>MS Automotive Engineering, Clemson University (CU-ICAR). Specialized in Powertrains, Controls and Calibration.</li>
                    </Paragraph>

                    <Paragraph>
                        <li>In past roles, led powertrain testbed operations, test SW development and configuration.</li>
                        <li>Experience on C#, with other projects C++, Python, MATLAB, Java, and SQL.</li>
                        <li>Prior expereince at Tata Motors in Supply Chain, supporting Vehicle Development and Manufacturing</li>
                    </Paragraph>

                    
                </>
            )}
        </Section>
    );
}

export default About;
