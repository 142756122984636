import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #333;
`;

const ContactInfo = styled.p`
  font-size: 18px;
  color: #555;

  a {
    color: #0073b1; /* LinkedIn blue color */
    text-decoration: none;
    font-weight: bold;
    
    &:hover {
      color: #005c8d; /* Darker shade for hover effect */
      text-decoration: underline;
    }
  }
`;

const Contact = () => {
    return (
        <Section id="contact">
            <Title>Contact</Title>
            <ContactInfo>Email: shashmaurya@gmail.com</ContactInfo>
            <ContactInfo>
                <a href="https://www.linkedin.com/in/shashimaurya/" target="_blank" rel="noopener noreferrer">Visit my LinkedIn</a>
            </ContactInfo>
        </Section>
    );
}

export default Contact;
