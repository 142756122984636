import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #333;
`;

const EducationItem = styled.div`
  margin-bottom: 20px;
`;

const Degree = styled.h3`
  font-size: 24px;
  color: #333;
`;

const School = styled.p`
  font-size: 18px;
  color: #777;
`;

const Education = () => {
    return (
        <Section id="education">
            <Title>Education</Title>
            <EducationItem>
                <Degree>MS | Automotive Engineering</Degree>
                <School>Clemson University - ICAR, SC, USA | 2015 - 2017</School>
            </EducationItem>


            <EducationItem>
                <Degree>B.Tech | Mechanical Engineering</Degree>
                <School>NIT Calicut, Kerala, India| 2008 - 2012</School>
            </EducationItem>

        </Section>
    );
}

export default Education;
