import React from 'react';
import ReactDOM from 'react-dom/client'; // Note: `react-dom/client` for React 18+
import './index.css'; // If you want to add global styles or reset CSS
import App from './App'; // Import the main App component

// Get the root DOM element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component inside the root element
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
