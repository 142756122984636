import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #333;
`;

const EducationItem = styled.div`
  margin-bottom: 20px;
`;

const Degree = styled.h3`
  font-size: 24px;
  color: #333;
`;

const School = styled.p`
  font-size: 18px;
  color: #777;

  a {
    color: #0073b1; /* LinkedIn blue color */
    text-decoration: none;
    font-weight: bold;
    
    &:hover {
      color: #005c8d; /* Darker shade for hover effect */
      text-decoration: underline;
    }
  }
`;

const Education = () => {
    return (
        <Section id="education">
            <Title>Certifications</Title>
            <EducationItem>
                <Degree>Self-Driving Car Engineer Nanodegree</Degree>
                <School>Udacity | 2020 </School>
                <School>
                    Credential ID: P6FKN33A |{' '}
                    <a href="https://www.udacity.com/certificate/e/a4f17056-71d6-11ea-97c2-9fd0911d4f48" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>

            <EducationItem>
                <Degree>Machine Learning</Degree>
                <School>Coursera | 2016 </School>
                <School>
                    Credential ID: RSXCPSBLC56F |{' '}
                    <a href="https://www.coursera.org/account/accomplishments/certificate/RSXCPSBLC56F" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>

            <EducationItem>
                <Degree>Introduction to Data Science in Python</Degree>
                <School>Coursera | 2024 </School>
                <School>
                    Credential ID: ASCN9WZPTHZR |{' '}
                    <a href="https://www.coursera.org/account/accomplishments/verify/ASCN9WZPTHZR" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>

            <EducationItem>
                <Degree>Applied Machine Learning in Python</Degree>
                <School>Coursera | 2024 </School>
                <School>
                    Credential ID: VPNL65G9PJEF |{' '}
                    <a href="https://www.coursera.org/account/accomplishments/verify/VPNL65G9PJEF" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>


            <EducationItem>
                <Degree>Applied Text Mining in Python</Degree>
                <School>Coursera | 2024 </School>
                <School>
                    Credential ID: J5AABGFN755M |{' '}
                    <a href="https://www.coursera.org/account/accomplishments/verify/J5AABGFN755M" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>

            <EducationItem>
                <Degree>Applied Social Network Analysis in Python</Degree>
                <School>Coursera | 2024 </School>
                <School>
                    Credential ID: 89YBDFYNR9A3 |{' '}
                    <a href="https://www.coursera.org/account/accomplishments/certificate/89YBDFYNR9A3" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>

            <EducationItem>
                <Degree>Applied Plotting, Charting, and Data Representation in Python</Degree>
                <School>Coursera | 2024 </School>
                <School>
                    Credential ID: XJ39D2SXBE2X |{' '}
                    <a href="https://www.coursera.org/account/accomplishments/verify/XJ39D2SXBE2X" target="_blank" rel="noopener noreferrer">
                        View Credential
                    </a>
                </School>
            </EducationItem>

        </Section>
    );
}

export default Education;
