import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components
const Section = styled.section`
  padding: 60px 20px;
  background-color: #fff;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #333;
  animation: fadeIn 1s ease-out; /* Fade in for section title */
`;

const CompanySection = styled.div`
  margin-bottom: 30px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;  /* Ensure the Company section is always visible */
  transform: translateY(0);  /* No translation needed for Company name visibility */
`;

const Job = styled.div`
  margin-bottom: 20px;
`;

const Company = styled.p`
  font-size: 24px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;

  &:hover {
    color: #ff6347; /* Change color on hover */
  }
`;

const JobTitle = styled.h3`
  font-size: 20px;
  color: #333;
  margin: 10px 0;
  margin-left: 20px;
`;

const JobTimeline = styled.p`
  font-size: 18px;
  color: #777;
  margin: 5px 0;
  margin-left: 30px;
`;

const JobDescription = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-left: 40px; /* Added indentation for clarity */
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: transform 0.3s ease-in-out;

  &:hover {
    color: #ff6347; /* Optional hover effect */
  }

  &.rotate {
    transform: rotate(45deg); /* Rotate the "+" or "−" button */
  }
`;

const Experience = () => {
    // State to track visibility of each company section
    const [openStates, setOpenStates] = useState({
        aptiv: false,
        lhp: false,
        zf: false,
        clemson: false,
        tata: false,
    });

    // Toggle function to handle any company section
    const toggleSection = (company) => {
        setOpenStates((prevState) => ({
            ...prevState,
            [company]: !prevState[company],
        }));
    };

    const companyData = [
        {
            name: 'Aptiv',
            duration: '2021 - Present',
            key: 'aptiv',
            jobs: [
                {
                    title: 'Engineering Team Manager - Vehicle Testing',
                    timeline: '2022 - Present',
                    description: (
                        <>
                            Leading a group of 20+ Test Engineers across multiple projects to deliver vehicle DVPnR and System Test Design activities.
                            <ul>
                                <li>Hiring and training the team</li>
                                <li>Aligning team capabilities with customer requirements</li>
                                <li>Continuous improvement of test methodologies</li>
                            </ul>
                        </>
                    ),
                },
                {
                    title: 'Project Owner - Vehicle Test Architecture',
                    timeline: '2021 - 2022',
                    description: (
                        <>
                            Working as a Product Owner for Vehicle System Test Architecture, supporting Vehicle Testing and Verification operations for ADAS Features.
                            <ul>
                                <li>Planning and oversight of testing activities for DVPnR testing for SYS5 level on the V model</li>
                                <li>Coordinating creation of test cases for feature coverage over needed conditions and scenarios</li>
                                <li>Maintaining necessary linkage and structure for ASPICE compliance</li>
                                <li>Decisions on testing scope and coverage for DVPnR, based on external and internal inputs</li>
                                <li>Reporting results for each phase of testing on a program level</li>
                            </ul>
                        </>
                    ),
                },
            ],
        },
        {
            name: 'LHP Engineering Solutions',
            duration: '2017 - 2021',
            key: 'lhp',
            jobs: [
                {
                    title: 'Developer - Electronic Tools',
                    timeline: '2020 - 2021',
                    description: (
                        <>
                            Worked with the Electronic Tools Team for the development of internal tools to support engine test operations. Prominent tasks included:
                            <ul>
                                <li>Application Development for new features on Calterm - a tool for ECU calibration, monitoring</li>
                                <li>Resolving Change/Defect Requests</li>
                                <li>Performing unit testing for features. Created Python and VB scripts, APIs for testbed automation</li>
                            </ul>
                        </>
                    ),
                },
                {
                    title: 'Application Engineer',
                    timeline: '2017 - 2020',
                    description: (
                        <>
                            Worked at Cummins, through LHP Engineering Solutions, for projects on Light-Duty Diesel Testing Facility. Current deliverable includes:
                            <ul>
                                <li>Supporting engine testbed operations, configuration to execute test plans, and Troubleshooting</li>
                                <li>Driving a project to migrate existing systems to the latest versions of AVL PUMA OPEN 2 (performance) and AVL iGEM 2 (Emissions) Packages</li>
                                <li>Develop and test customized modules on the aforementioned off-the-shelf packages</li>
                                <li>Pre and post-test data extraction, and processing; Write VBA and .Net applications for the same</li>
                                <li>Improving consistency and repeatability of test sequences to save valuable test-cell time</li>
                            </ul>
                        </>
                    ),
                },
            ],
        },
        {
            name: 'ZF Group',
            duration: '2017',
            key: 'zf',
            jobs: [
                {
                    title: 'Intern - Customer Quality Engineering',
                    timeline: '2017',
                    description: (
                        <>
                            Completed industrial internship as a requirement of the MS degree program. Drove the following projects at ZF, among others:
                            <ul>
                                <li>Reduction in Open Zero-Mile/Warranty Cases - Reducing the lead time in problem-solving for open issues. Prioritizing tear-down audits based on criticality and recurrence. Created Macro-enabled tracking sheets for monitoring and prioritization.</li>
                                <li>Internal Performance Evaluation - Creating internal scorecards for team’s performance measurement against benchmark parameters. Improving visibility of KPIs across teams through standardized automated reports using Excel VBA Macros.</li>
                            </ul>
                        </>
                    ),
                },
            ],
        },
        {
            name: 'Clemson University',
            duration: '2016',
            key: 'clemson',
            jobs: [
                {
                    title: 'Student Support - Clemson Computing and IT',
                    timeline: '2016',
                    description: (
                        <>
                            Worked as a Student Support Officer for Clemson Computing and IT department, part-time. Job responsibilities include:
                            <ul>
                                <li>Software and Hardware installations</li>
                                <li>IT support and Troubleshooting for Students, Faculty, and Staff</li>
                                <li>Routine checks of campus AV facilities</li>
                                <li>Provide occasional IT support for campus events</li>
                            </ul>
                        </>
                    ),
                },
            ],
        },
        {
            name: 'Tata Motors Ltd',
            duration: '2012-2015',
            key: 'tata',
            jobs: [
                {
                    title: 'Assistant Manager',
                    timeline: '2012 - 2015',
                    description: (
                        <>
                            Worked on the Supply Chain team, with the following deliverables:
                            <ul>
                                <li>Inventory reduction - Continuous monitoring and lowering of the standard norms for inventory, leading to inventory reduction by more than 30% in 24 months.</li>
                                <li>Migration to MRP automation from manual scheduling - Implementing system-generated parts’ requirement. Responsible for testing the accuracy of the MRP (Material Resource Planning) system before roll-out.</li>
                                <li>Mitigation of supplier quality issues - Supported Advance Quality and Vendor Quality Assurance departments in resolution of quality issues, through the development process.</li>
                                <li>Cross-Functional Team - Part of the team working on productionization of GB-85, a new 6-speed gearbox. Support function for APQP (Advanced Product Quality Planning), and PPAP (Production Part Approval Process).</li>
                            </ul>
                        </>
                    ),
                },
            ],
        },
    ];

    return (
        <Section id="experience">
            <Title>Experience</Title>
            {companyData.map((company) => (
                <CompanySection key={company.key}>
                    <Company onClick={() => toggleSection(company.key)}>
                        {company.name} | {company.duration}
                        <ToggleButton className={openStates[company.key] ? 'rotate' : ''}>
                            {openStates[company.key] ? '−' : '+'}
                        </ToggleButton>
                    </Company>
                    {openStates[company.key] &&
                        company.jobs.map((job, idx) => (
                            <Job key={idx}>
                                <JobTitle>{job.title}</JobTitle>
                                <JobTimeline>{job.timeline}</JobTimeline>
                                <JobDescription>{job.description}</JobDescription>
                            </Job>
                        ))}
                </CompanySection>
            ))}
        </Section>
    );
};

export default Experience;
