import React, { useState } from 'react';
import Navbar from './Navbar';
import About from './About';
import Skills from './Skills';
import Experience from './Experience';
import Education from './Education';
import Certifications from './Certifications';
import Projects from './Projects';
import Contact from './Contact';
import styled from 'styled-components';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Importing the icons

// Importing the image
import profileImage from './assets/Profile.jpg';

// Styled components
const Banner = styled.header`
  background-color: #1c1c1c; /* Dark, modern gray */
  color: #f0f0f0; /* Soft white */
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn 1s ease-out;
`;

const BannerText = styled.h1`
  font-size: 36px;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px; /* Slightly spaced for a more elegant feel */
`;

const BannerImage = styled.img`
  width: 80px;
  height: auto;
  border-radius: 50%;
`;

const Container = styled.div`
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f1f1f1; /* Light gray background for a modern look */
`;

const Section = styled.section`
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
`;

const SectionContent = styled.div`
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transform: ${props => (props.isOpen ? 'translateY(0)' : 'translateY(20px)')};
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  color: #333; /* Dark gray color for title */
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  padding: 10px 0;
  font-weight: 600;

  &:hover {
    color: #ff6347; /* Modern soft accent color */
  }
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: transform 0.3s ease-in-out, color 0.3s ease;

  &:hover {
    color: #ff6347; /* Accent color on hover */
  }

  &:active {
    transform: scale(1.1); /* Slight scaling on click */
  }

  &.rotate {
    transform: rotate(45deg); /* Rotate for the open state */
  }
`;

const App = () => {
    // State to track visibility of each section
    const [isAboutOpen, setIsAboutOpen] = useState(true);
    const [isSkillsOpen, setIsSkillsOpen] = useState(true);
    const [isExperienceOpen, setIsExperienceOpen] = useState(true);
    const [isEducationOpen, setIsEducationOpen] = useState(true);
    const [isCertificationsOpen, setIsCertificationsOpen] = useState(true);
    const [isProjectsOpen, setIsProjectsOpen] = useState(true);
    const [isContactOpen, setIsContactOpen] = useState(true);

    // Toggle functions for each section
    const toggleAbout = () => setIsAboutOpen(!isAboutOpen);
    const toggleSkills = () => setIsSkillsOpen(!isSkillsOpen);
    const toggleExperience = () => setIsExperienceOpen(!isExperienceOpen);
    const toggleEducation = () => setIsEducationOpen(!isEducationOpen);
    const toggleCertifications = () => setIsCertificationsOpen(!isCertificationsOpen);
    const toggleProjects = () => setIsProjectsOpen(!isProjectsOpen);
    const toggleContact = () => setIsContactOpen(!isContactOpen);

    return (
        <Container>
            <Navbar />
            <Banner>
                <BannerText>Shashi Maurya</BannerText>
                <BannerImage src={profileImage} alt="Profile Picture" />
            </Banner>

            {/* About Section */}
            <Section id="about">
                <SectionHeader onClick={toggleAbout}>
                    About Me
                    <ToggleButton className={isAboutOpen ? 'rotate' : ''}>
                        {isAboutOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isAboutOpen}>
                    {isAboutOpen && <About />}
                </SectionContent>
            </Section>

            {/* Skills Section */}
            <Section id="skills">
                <SectionHeader onClick={toggleSkills}>
                    Skills
                    <ToggleButton className={isSkillsOpen ? 'rotate' : ''}>
                        {isSkillsOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isSkillsOpen}>
                    {isSkillsOpen && <Skills />}
                </SectionContent>
            </Section>

            {/* Experience Section */}
            <Section id="experience">
                <SectionHeader onClick={toggleExperience}>
                    Experience
                    <ToggleButton className={isExperienceOpen ? 'rotate' : ''}>
                        {isExperienceOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isExperienceOpen}>
                    {isExperienceOpen && <Experience />}
                </SectionContent>
            </Section>

            {/* Education Section */}
            <Section id="education">
                <SectionHeader onClick={toggleEducation}>
                    Education
                    <ToggleButton className={isEducationOpen ? 'rotate' : ''}>
                        {isEducationOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isEducationOpen}>
                    {isEducationOpen && <Education />}
                </SectionContent>
            </Section>

            {/* Certifications Section */}
            <Section id="certifications">
                <SectionHeader onClick={toggleCertifications}>
                    Certifications
                    <ToggleButton className={isCertificationsOpen ? 'rotate' : ''}>
                        {isCertificationsOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isCertificationsOpen}>
                    {isCertificationsOpen && <Certifications />}
                </SectionContent>
            </Section>

            {/* Projects Section */}
            <Section id="projects">
                <SectionHeader onClick={toggleProjects}>
                    Projects
                    <ToggleButton className={isProjectsOpen ? 'rotate' : ''}>
                        {isProjectsOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isProjectsOpen}>
                    {isProjectsOpen && <Projects />}
                </SectionContent>
            </Section>

            {/* Contact Section */}
            <Section id="contact">
                <SectionHeader onClick={toggleContact}>
                    Contact
                    <ToggleButton className={isContactOpen ? 'rotate' : ''}>
                        {isContactOpen ? <FaMinus /> : <FaPlus />} {/* Use icons */}
                    </ToggleButton>
                </SectionHeader>
                <SectionContent isOpen={isContactOpen}>
                    {isContactOpen && <Contact />}
                </SectionContent>
            </Section>
        </Container>
    );
};

export default App;
