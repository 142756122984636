import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #333;
`;

const SkillList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const SkillItem = styled.li`
  font-size: 20px;
  color: #555;
  margin: 10px 0;
`;

const Skills = () => {
    return (
        <Section id="skills">
            <Title>Skills</Title>
            <SkillList>
                <SkillItem>Automotive Testing - Systems Level, Bench, Testbeds</SkillItem>
                <SkillItem>Coding Languages: Python, C++, C#</SkillItem>
                <SkillItem>Database: SQL</SkillItem>
                <SkillItem>Engineering Tools: Matlab, Simulink, </SkillItem>
                <SkillItem>Machine Learning - Supervised and Unsupervised, scikit-learn, Pandas, Numpy  </SkillItem>
                <SkillItem>Version and Repo Management: Git & GitHub</SkillItem>
            </SkillList>
        </Section>
    );
}

export default Skills;
