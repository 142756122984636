import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 60px 20px;
  background-color: #fff;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #333;
`;

const Job = styled.div`
  margin-bottom: 20px;
`;

const ProjectTitle = styled.h3`
  font-size: 24px;
  color: #333;
`;

const Company = styled.p`
  font-size: 18px;
  color: #777;
`;

const Experience = () => {
    return (
        <Section id="experience">
            <Title>Projects</Title>

            <Job>
                <ProjectTitle>Train a CNN Model Using Keras to Clone Driving Behavior</ProjectTitle>
                <Company>
                    <a href="https://github.com/shashmaurya/CarND-Behavioral-Cloning-P3" target="_blank" color="#005c8d" rel="noopener noreferrer">Github Link</a> 
                     - 2020
                </Company>
                <p>
                    
                    Following steps were taken to complete this project:
                    <li>Used a simulator to collect data of good driving behavior</li>
                    <li>Designed, trained and validated a model that predicts a steering angle from image data</li>
                    <li>Used the model to drive the vehicle autonomously around the first track in the simulator. It was a requirement that the vehicle remains on the road for an entire loop around the training track.</li>
                </p>
            </Job>

            <Job>
                <ProjectTitle>Path Planning to Navigate Around a Virtual Highway</ProjectTitle>
                <Company>
                    <a href="https://github.com/shashmaurya/CarND-Path-Planning-Project" target="_blank" rel="noopener noreferrer">Github Link</a>
                    - 2020
                </Company>
                <p>
                    Following tasks were accomplished to complete the project:
                    <li>Write code to plan vehicle trajectory in C++ on a Highway track in a Simulator</li>
                    <li>Used the provided localization, sensor data, and waypoints</li>
                    <li>Trajectory updates include stopping, lane changes based on traffic</li>
                    <li>Car completes the track avoiding collisions, and following speed, acceleration, and jerk limits</li>
                </p>
            </Job>

            <Job>
                <ProjectTitle>Implement Kalman Filter to Estimate State of a Moving Object</ProjectTitle>
                <Company>
                    <a href="https://github.com/shashmaurya/CarND-Extended-Kalman-Filter-Project" target="_blank" rel="noopener noreferrer">Github Link</a>
                    - 2020
                </Company>
                <p>
                    Accomplished following tasks for the project:
                    <li>Implemented an Extended Kalman Filter in C++</li>
                    <li>Combined data from noisy Lidar and Radar measurements to estimate position of an object</li>
                    <li>Tuned parameters to achieve RMSE values below the required tolerance</li>
                </p>
            </Job>


            <Job>
                <ProjectTitle>Advanced Lane Finding</ProjectTitle>
                <Company>
                    <a href="https://github.com/shashmaurya/CarND-Advanced-Lane-Lines" target="_blank" rel="noopener noreferrer">Github Link</a>
                    - 2020
                </Company>
                <p>
                    Tasks accomplished to meet the project objectives
                    <li>Computed the camera calibration matrix and distortion coefficients, given a set of chessboard images.</li>
                    <li>Applied a distortion correction, color transforms, gradients to create a thresholded binary image.</li> 
                    <li>Applied a perspective transform to rectify binary image ("birds-eye view").</li>
                    <li>Detected lane pixels, curavate, and fit to find the lane boundary.</li>
                    <li>Output visual display of the lane boundaries and numerical estimation of lane curvature and vehicle position.</li>
                </p>
            </Job>


            <Job>
                <ProjectTitle>Traffic Sign Recognition Using LeNet Model Architecture</ProjectTitle>
                <Company>
                    <a href="https://github.com/shashmaurya/CarND-Traffic-Sign-Classifier-Project" target="_blank" rel="noopener noreferrer">Github Link</a>
                    - 2020
                </Company>
                <p>
                    Projects outcomes

                    <li>Load, Explore, summarize and visualize the data set</li>
                    <li>Design, train and test a model architecture</li>
                    <li>Use the model to make predictions on new images</li>
                    <li>Analyze the Softmax probabilities of the new images</li>

                </p>
            </Job>


            <Job>
                <ProjectTitle>Air-Hybrid Engine (Scuderi) Modelling and Simulation in GT-Power</ProjectTitle>
                <Company>Clemson University | 2016</Company>
                <p>
                <li>Course project with focus on modelling and simulation of a Scuderi engine in GT-Power.</li>
                <li>Investigated possibility of implementing the concept for CI engines</li>
                </p>
            </Job>
            <Job>
                <ProjectTitle>Numerical Analysis of Convective Heat Transfer in a 2D Square Cavity using C++</ProjectTitle>
                <Company>NIT Calicut | 2011 - 2012</Company>
                <p>
                    <li>Developed indigenous code, for solving a classical problem of heat flow in 2D square cavity with convective flow, differentially heated on one face.</li>
                    <li>Problem formulated with non-dimensional governing equations; tridiagonal approximation for iteration and convergence. Used iterative numerical method for nodal evaluation.</li>
                    <li> Evaluate temperature distribution based on user input for mesh-size; higher accuracy, convergence on increasing nodes. Results validated with benchmark literature</li>.
                </p>
            </Job>
        </Section>
    );
}

export default Experience;
