import React from 'react';
import styled from 'styled-components';
import { FaUser, FaCogs, FaBriefcase, FaGraduationCap, FaCertificate, FaProjectDiagram, FaEnvelope } from 'react-icons/fa'; // Optional icons for each section

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1c1c1c; /* Dark background */
  padding: 15px 0;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for a floating effect */
  animation: slideDown 0.5s ease-out;
`;

const NavLinks = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavLink = styled.li`
  margin: 0 20px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase; /* Add a more professional touch with uppercase letters */

  a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 0.3s ease, transform 0.2s ease;
  }

  a:hover {
    color: #ff6347; /* Tomato color on hover */
    transform: scale(1.1); /* Slight scale effect on hover */
  }

  svg {
    margin-right: 8px; /* Space between the icon and the text */
    transition: transform 0.3s ease;
  }

  a:hover svg {
    transform: translateX(5px); /* Smooth icon animation */
  }
`;

const Navbar = () => {
    return (
        <Nav>
            <NavLinks>
                <NavLink><a href="#about"><FaUser />About</a></NavLink>
                <NavLink><a href="#skills"><FaCogs />Skills</a></NavLink>
                <NavLink><a href="#experience"><FaBriefcase />Experience</a></NavLink>
                <NavLink><a href="#education"><FaGraduationCap />Education</a></NavLink>
                <NavLink><a href="#certifications"><FaCertificate />Certifications</a></NavLink>
                <NavLink><a href="#projects"><FaProjectDiagram />Projects</a></NavLink>
                <NavLink><a href="#contact"><FaEnvelope />Contact</a></NavLink>
            </NavLinks>
        </Nav>
    );
}

export default Navbar;
